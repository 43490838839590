<template>
  <div>
    <HeaderPanel
      title="Upload"
      :hasDropdown="false"
      :hasFilter="false"
      :hasSearch="false"
    />
    <div class="bg-white border-red p-2 p-lg-3 mt-3">
      <b-row class="no-gutters">
        <b-col class="text-black">
          <b-form-group class="">
            <template v-slot:label>
              <label>Company Type <span class="text-danger">*</span></label>
            </template>
            <b-form-radio-group
              v-model="form.companyType"
              :options="options"
              value-field="id"
              text-field="name"
            ></b-form-radio-group>
          </b-form-group>
          <div v-if="$v.form.companyType && $v.form.companyType.$error">
            <span
              class="text-error"
              v-if="$v.form.companyType.required == false"
              >This field can’t be empty</span
            >
            <span
              class="text-error"
              v-else-if="$v.form.companyType.minValue == false"
              >This field can’t be empty</span
            >
          </div>
        </b-col>
        <b-col md="12" class="justify-content-start">
          <UploadFileV2
            textFloat="File"
            placeholder="Please Choose File"
            format="excel"
            name="file"
            text="*Please upload only file .xlsx less than 10 MB"
            isRequired
            v-on:onFileChange="onFileChange"
            v-on:delete="deleteFile"
            :fileName="filename"
            v-model="form.excel_file"
            :v="$v.form.excel_file"
            id="uploadfile"
          />
        </b-col>
      </b-row>
      <b-row v-for="(item, index) in form.emails" :key="index">
        <b-col cols="6">
          <InputText
            textFloat="Email"
            placeholder="Email"
            type="text"
            name="email"
            isRequired
            detail="Please enter your email, We will send import result to your email"
            v-model="item.value"
            :v="$v.form.emails.$each.$iter[index].value"
            :isValidate="$v.form.emails.$each.$iter[index].value.$error"
          />
        </b-col>
        <b-col cols="1" class="mt-3 pt-3 d-flex">
          <div :class="'icon-primary'" @click="handleDelete(index)">
            <font-awesome-icon
              icon="trash-alt"
              :class="'pointer color-primary'"
              color="#B41BB4"
            />
          </div>
          <div
            v-if="index == form.emails.length - 1"
            :class="'icon-primary'"
            @click="handleAddEmail()"
          >
            <font-awesome-icon
              icon="plus"
              :class="'pointer color-primary'"
              color="#B41BB4"
            />
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="1"> </b-col>
      </b-row>
      <b-row>
        <b-col> </b-col>
        <b-col class="text-right">
          <b-button
            id="upload"
            class="main-color btn-save color-btn"
            size="md"
            :disabled="isLoading"
            @click="importData"
            >Upload</b-button
          >
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import UploadFileV2 from "@/components/inputs/UploadFileV2";
import { required, email, minValue, minLength } from "vuelidate/lib/validators";
export default {
  components: {
    UploadFileV2,
  },
  validations: {
    form: {
      companyType: { required, minValue: minValue(1) },
      excel_file: { required },
      emails: {
        $each: { value: { email, required } },
        minLength: minLength(1),
      },
    },
  },
  data() {
    return {
      isLoading: false,
      form: {
        companyType: 0,
        email: [],
        emails: [],
        // branch_id: "",
        file_name: "",
        excel_file: "",
        note: "",
      },
      filename: "",
      options: [
        { name: "Central", id: 2 },
        { name: "Robinson", id: 1 },
      ],
    };
  },
  async created() {
    this.form.user_guid = this.$store.state.auth.ShortProfile.id;
    await this.getEmail();
  },
  methods: {
    onFileChange(file) {
      this.filename = file.name;
      this.form.file_name = file.name;
      const str =
        "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.form.excel_file = reader.result.substring(
          str.length,
          reader.result.length
        );
      };
    },
    deleteFile() {
      this.form.excel_file = null;
      this.filename = null;
    },
    importData() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }
      this.sendForm();
    },
    handleAddEmail(email = "") {
      this.form.emails.push({ value: email });
    },
    handleDelete(index) {
      if (this.form.emails.length === 1 && index === 0) {
        this.form.emails[0].value = "";
      } else {
        let list = this.form.emails;
        list.splice(index, 1);
      }
    },
    async getEmail() {
      this.isLoading = true;
      let body = this.form;
      body.email = this.form.emails.map(({ value }) => value);
      await this.$store.dispatch("getEmails", body);
      let data = this.$store.state.upload.emails;
      if (data.result === 1) {
        this.isLoading = false;
        if (data.detail.length == 0)
          this.handleAddEmail(this.$store.state.auth.ShortProfile.email);
        else {
          await data.detail.forEach((item) => {
            this.handleAddEmail(item.email);
          });
        }
      } else {
        this.isLoading = false;
        this.$swal(data.message, {
          icon: "warning",
        });
      }
    },
    async sendForm() {
      await this.$store.dispatch("setMainLoading", true);
      this.isLoading = true;
      let body = this.form;
      body.email = this.form.emails.map(({ value }) => value);
      await this.$store.dispatch("uploadFile", body);
      let data = this.$store.state.upload.respUpload;
      if (data.result === 1) {
        if (data.detail?.errorMessage) {
          this.$swal(data.detail.errorMessage, {
            icon: "warning",
          });
        } else {
          this.$swal("Import Success!", {
            icon: "success",
          });
          // clear state
          this.form.companyType = 0;
          this.form.file_name = "";
          this.form.excel_file = "";
          this.filename = "";

          this.$v.form.$reset();
        }
      } else {
        this.$swal(data.message, {
          icon: "warning",
        });
      }
      this.isLoading = false;
      await this.$store.dispatch("setMainLoading", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.color-btn {
  color: #fff;
}
</style>
